import React, { useEffect, useState } from 'react';
import './parcel-details-form.scss';
import Input from 'antd/es/input/Input';
import { Form, Select } from 'antd';
import { customPackageSvg } from '../../../../Resource/images';
import { createMultiplePackagesArray } from '../../../../Util/commonUtility';
import { commonConfig } from '../../../../Config/config';

const ParcelDetailsForm = React.forwardRef((props, ref) => {
  const {
    form,
    id,
    packages,
    deletePackage,
    setPackagesArr,
    setDynamicDescription,
    dynamicPackageDescription,
    QUANTITY_LIMIT,
  } = props;
  // TODO: remove this "maxWeightLimit" state , its conflicting Condition in Weight validator
  const [maxWeightLimit, setMaxWeight] = useState();
  const [packageQuantity, setPackageQuantity] = useState(
    form.getFieldValue(`packageQuantity${id}`) || 1
  );
  const [weightError, setWeightError] = useState(false);
  const { Option } = Select;
  const quantityCounter = opration => {
    opration === 'add'
      ? setPackageQuantity(pre => Number(pre) + 1)
      : setPackageQuantity(pre => Number(pre) - 1);
    form.setFieldsValue(
      opration === 'add'
        ? { [`packageQuantity${id}`]: Number(packageQuantity) + 1 }
        : packageQuantity > 1
        ? { [`packageQuantity${id}`]: Number(packageQuantity) - 1 }
        : null
    );
    setPackagesArr(createMultiplePackagesArray(form.getFieldValue()));
  };
  const increaseQuantity = () => {
    if (
      Number(form.getFieldValue([`packageQuantity${id}`])) >= QUANTITY_LIMIT
    ) {
      form.setFieldsValue({ [`packageQuantity${id}`]: QUANTITY_LIMIT });
      return;
    }
    quantityCounter('add');
  };
  const decreseQuantity = () => {
    if (packageQuantity > 1) {
      quantityCounter('minus');
    }
  };

  useEffect(() => {
    setDynamicDescription(dynamicPackageDescription());
  }, [packageQuantity]);

  const selectBefore = <button onClick={decreseQuantity}>-</button>;
  const selectAfter = <button onClick={increaseQuantity}>+</button>;

  const onPackageSelectHandler = async (pkg, initForm) => {
    setMaxWeight(pkg?.Max_Weight);
    //naming form fields according to id,
    let newId = id + 1;
    if (!initForm) {
      await form.setFieldsValue({
        // setting fields value as per name
        [`package${newId}`]: pkg?.PackagingType,
        [`combinedWeight${newId}`]: pkg?.Weight,
        [`packageLength${newId}`]: pkg?.Length,
        [`packageWidth${newId}`]: pkg?.Width,
        [`packageHeight${newId}`]: pkg?.Height,
      });
    }
    setWeightError(
      pkg?.Weight > pkg?.Max_Weight ? true : false
    );
    setPackagesArr(createMultiplePackagesArray(form.getFieldValue()));
  };

  const formInitialValues = {
    [`packageQuantity${id}`]: 1,
    [`package${id}`]: 'Select Package',
  };

  const newOptions = [
    {
      PackagingType: 'Select Package',
      Weight: '',
      Max_Weight: '',
      Length: '',
      Width: '',
      Height: '',
    },
    ...packages,
  ];

  const options = newOptions?.map((pkg, i) => {
    return {
      key: i,
      value: (
        <div className='package' key={pkg?.PackagingType}>
          {pkg?.PackagingType}
        </div>
      ),
      pkg,
    };
  });

  const weightValidator = (data, value) => {
    const getedMaxWeight = newOptions.filter(
      val => val.PackagingType === form.getFieldValue(`package${id}`)
    )[0]?.Max_Weight;

    if (Number(value) < 0) {
      form.setFieldsValue({ [data?.field]: 0 });
      return Promise.reject(new Error());
    }
    if (Number(value) > 1000) {
      form.setFieldsValue({ [`combinedWeight${id}`]: 1000 });
    }
    if (form.getFieldValue(`package${id}`) === 'Select Package') {
      setWeightError(false);
      return Promise.resolve();
    } else {
      if (value && Number(value) > getedMaxWeight) {
        setWeightError(true);
        data.message = '';
        return Promise.reject(new Error());
      }
      setWeightError(false);
      return Promise.resolve();
    }
  };

  const negativeNumberValidator = (data, value) => {
    if (Number(value) <= 0 || !value) {
      if (data.field.includes('packageLength')) {
        return Promise.reject(new Error('Length is required'));
      }
      if (data.field.includes('packageWidth')) {
        return Promise.reject(new Error('Width is required'));
      }
      if (data.field.includes('packageHeight')) {
        return Promise.reject(new Error('Height is required'));
      }
    }
    if (Number(value) >= QUANTITY_LIMIT) {
      if (data?.field === `packageQuantity${id}`) {
        setPackageQuantity(QUANTITY_LIMIT);
      }
      form.setFieldsValue({ [data?.field]: QUANTITY_LIMIT });
      setPackagesArr(createMultiplePackagesArray(form.getFieldValue()));
    }
    if (!value) {
      return Promise.reject(new Error());
    } else if (value <= 0) {
      if (data.field.includes('packageQuantity') && value <= 0) {
        form.setFieldsValue({ [data?.field]: 1 });
      } else {
        form.setFieldsValue({ [data?.field]: 0 });
      }
    }
    return Promise.resolve();
  };

  React.useImperativeHandle(ref, () => ({
    onPackageSelectHandler: onPackageSelectHandler,
    id,
  }));

  const onCounterChangeHandler = e => {
    form.setFieldsValue({ [`packageQuantity${id}`]: replaceNumberOnly(e.target.value) })
    setPackageQuantity(replaceNumberOnly(e.target.value));
    if (Number(e.target.value) >= QUANTITY_LIMIT) {
      setPackageQuantity(
        Number(e.target.value) >= QUANTITY_LIMIT
          ? QUANTITY_LIMIT
          : Number(e.target.value)
      );
      return;
    }
    setPackagesArr(createMultiplePackagesArray(form.getFieldValue()));
  };
  const deletePackageHandler = () => {
    deletePackage(id);
    setPackageQuantity(1);
    setWeightError(false)
  };
  const replaceNumberOnly = num => {
    const replacedNum = String(num)?.replace(/[^0-9]/g, '');
    return Number(replacedNum);
  };

  return (
    <div className='package-content-wrapper'>
      <div className='package-form-wrapper'>
        <Form
          name='packages'
          form={form}
          className='package-form'
          initialValues={formInitialValues}
        >
          <div className='top-form'>
            <Form.Item name={`package${id}`}>
              <div className='package-label-wrapper'>
                {form.getFieldValue(`package${id}`) || 'Select Package'}
              </div>
            </Form.Item>
            <Form.Item
              name={`packageQuantity${id}`}
              className='counter-input'
              rules={[
                {
                  validator: negativeNumberValidator,
                },
              ]}
            >
              <Input
                addonBefore={selectBefore}
                addonAfter={selectAfter}
                min={1}
                onChange={e => onCounterChangeHandler(e)}
              />
            </Form.Item>
            <Form.Item
              name={`combinedWeight${id}`}
              className='combo-weight'
              validateFirst
              rules={[
                {
                  required: true,
                  message: 'Weight is required',
                  validator: (data, value) => {
                    if (value !== 0) {
                      form.setFieldsValue({
                        [`combinedWeight${id}`]: replaceNumberOnly(value),
                      });
                    }
                    if (value <= 0) {
                      return Promise.reject(new Error());
                    }
                    if (!value) {
                      setWeightError(false);
                      return Promise.reject(new Error());
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
                {
                  validator: weightValidator,
                },
              ]}
            >
              <Input
                className='package-input weight-input'
                maxLength={4}
                addonAfter={commonConfig.weightUnit}
                placeholder={`Combined Weight (${commonConfig.weightUnit})`}
                min={0}
              />
            </Form.Item>
            <div className='delete-package' onClick={deletePackageHandler}>
              <span className='pi pi-trash'></span>
            </div>
          </div>
          {weightError && (
            <div className='error-wrapper'>
              Weight limit exceeded for Current Package type. Please select my
              package type
            </div>
          )}

          <div className='bottom-form'>
            {!(form.getFieldValue(`package${id}`) === 'Skid') && (
              <>
                <Form.Item
                  name={`packageLength${id}`}
                  rules={[
                    {
                      validator: negativeNumberValidator,
                    },
                  ]}
                >
                  <Input
                    type='number'
                    min={0}
                    className='package-input'
                    addonAfter={commonConfig.HWLUnit}
                    placeholder={`Length (${commonConfig.HWLUnit})`}
                  />
                </Form.Item>
                <Form.Item
                  name={`packageWidth${id}`}
                  rules={[
                    {
                      validator: negativeNumberValidator,
                    },
                  ]}
                >
                  <Input
                    type='number'
                    className='package-input'
                    addonAfter={commonConfig.HWLUnit}
                    placeholder={`Width (${commonConfig.HWLUnit})`}
                    min={0}
                  />
                </Form.Item>
                <Form.Item
                  name={`packageHeight${id}`}
                  rules={[
                    {
                      validator: negativeNumberValidator,
                    },
                  ]}
                >
                  <Input
                    type='number'
                    className='package-input'
                    addonAfter={commonConfig.HWLUnit}
                    placeholder={`Height (${commonConfig.HWLUnit})`}
                    min={0}
                  />
                </Form.Item>
                <Form.Item name={`Description${id}`}>
                  <Input
                    type='text'
                    className='package-input pkg-desc-input'
                    placeholder='Description'
                  />
                </Form.Item>
              </>
            )}
          </div>
        </Form>
      </div>
      <div className='package-img-wrapper'>
        <img src={customPackageSvg} alt='' />
      </div>
    </div>
  );
});

export default ParcelDetailsForm;
