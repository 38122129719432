import { notification } from 'antd';
import { loginStatus } from '../Store/Reducers/Auth/AuthSlice';
import store from '../Store/store';
import { networkError } from './config';
import moment from 'moment';
import { orderFilterBtnsENUMS } from '../Constant/enums';

export const openNotification = (type, msg, desc) => {
  notification.open({
    message: msg,
    type: type,
    duration: 5,
    description: desc,
  });
  notification.config({
    maxCount: 1
  });
};

export const getCurrentAccessToken = () => {
  return localStorage.getItem('userAccessToken') || null;
};

export const getCurrentUserId = () => localStorage.getItem('userId') || null;

export const logout = (navigate, dispatch) => {
  localStorage.removeItem('userAccessToken');
  localStorage.removeItem('userId');
  dispatch(loginStatus(false));
  navigate('/login');

  return;
};

export const cheakIsUserLogin = () => {
  const token = localStorage.getItem('userAccessToken');
  const userId = localStorage.getItem('userId');
  if (!token && !userId) {
    store.dispatch(loginStatus(false));
  } else {
    store.dispatch(loginStatus(true));
  }
};

export const reloadPage = () => {
  window.location.pathname = '/';
};

export const manipulateString = inputString => {
  if (inputString?.length >= 45) {
    return inputString?.substring(0, 47) + '...';
  } else {
    return inputString;
  }
};

export const googlePlaceDataMasking = data => {
  const { address_components, geometry, name } = data;
  const maskedObj = {};
  maskedObj.latitudeLongitude = `${geometry?.location?.lat()},${geometry?.location?.lng()}`;
  maskedObj.addressLine1 = name;
  address_components?.forEach(element => {
    const elementType = element?.types?.[0];

    switch (elementType) {
      case 'administrative_area_level_2':
      case 'locality':
        maskedObj.city = element?.long_name || '';
        break;
      case 'country':
        maskedObj.country = element?.long_name || '';
        break;
      case 'postal_code':
      case 'postal_code_prefix':
        maskedObj.postalCode = element?.long_name || '';
        break;
      case 'administrative_area_level_1':
        maskedObj.state = element?.long_name || '';
        break;
    }
    if (elementType === 'postal_code' || elementType === 'postal_code_prefix') {
      maskedObj.postalCode = element?.long_name;
    } else {
      maskedObj.postalCode = '';
    }
  });

  return maskedObj;
};

export const checkIsOnline = () => {
  if (!navigator.onLine) {
    openNotification('error', 'Network Error', networkError);
  }
};

export const filterColumnOnSelect = (cols, value) => {
  // refecto logic with param
  let array = [];
  for (let i = 0; i < cols?.length; i++) {
    const currentCol = cols[i];
    if (
      value === 'All Orders' ||
      value === 'In-Transit' ||
      value === 'Completed'
    ) {
      if (currentCol?.field === 'Edit' || currentCol?.field === 'Delete') {
        array?.push({ field: currentCol.field, hide: true });
      } else {
        array?.push(currentCol);
      }
    } else {
            if (value === 'Draft') {
        if (currentCol?.field === 'Tracking Number') {
          array?.push({ field: currentCol?.field, hide: true });
        } else {
          array?.push(currentCol);
        }
      } else {
        array?.push(currentCol);
      }
    }
  }
  return array;
};
export const filterColumns = (cols, value) => {
  const currentCols = localStorage.getItem(
    `${getCurrentUserId()}_ColumnStateSaveOrderList`
  );
  const currentColsArr = JSON.parse(currentCols)?.filter(
    col => col.colId === 'TrackingNumber'
  )[0];
  return cols?.map(currentCol => {
    let hide = false;

    switch (value) {
      case orderFilterBtnsENUMS.allOrders:
      case orderFilterBtnsENUMS.delivered:
        if (currentCol?.field === 'Edit' || currentCol?.field === 'Delete') {
          hide = true;
        } else if (currentCol?.field === 'Tracking Number') {
          hide = currentColsArr?.hide;
        } else {
          hide = currentCol?.hide;
        }
        break;
      case orderFilterBtnsENUMS.draft:
        if (currentCol?.field === 'Edit' || currentCol?.field === 'Delete') {
          hide = false;
        } else if (currentCol?.field === 'Tracking Number') {
          hide = true;
        } else {
          hide = currentCol?.hide;
        }
        break;
      case orderFilterBtnsENUMS.submitted:
        if (currentCol?.field === 'Delete') {
          hide = false;
        }else if (currentCol?.field === 'Edit') {
          hide = true;
        }  else if (currentCol?.field === 'Tracking Number') {
          hide = currentColsArr?.hide;
        } else {
          hide = currentCol?.hide;
        }
        break;
      case orderFilterBtnsENUMS.inTransit:
        if (currentCol?.field === 'Edit') {
          hide = false;
        } else if (currentCol?.field === 'Tracking Number') {
          hide = currentColsArr?.hide;
        } else if (currentCol?.field === 'Delete') {
          hide = true;
        } else {
          hide = currentCol.hide;
        }
        break;
      default:
        hide =
          currentCol?.field === 'Edit' || currentCol?.field === 'Delete'
            ? false
            : currentCol.hide;
        break;
    }

    return { field: currentCol.field, hide };
  });
};

export const filterOrder = (arr, value, selectedColumn) => {
  if (value === 'All Orders') {
    return { data: arr, columns: filterColumns(selectedColumn, value) };
  } else {
    const rowData = arr?.filter(order => order['Order Status'] === value);
    return { data: rowData, columns: filterColumns(selectedColumn, value) };
  }
};

export const getMaskedColumnState = (grid, columnsArr) => {
  let arr = [];

  for (let i = 0; i < grid?.length; i++) {
    const element = grid[i];
    let filteredArr = columnsArr?.filter(col => col.key === element.colId)[0];
    filteredArr && arr?.push(filteredArr?.field);
  }
  return arr;
};

const HTTP_CODES = {
  SUCCESS: [200, 201, 202, 203],
  ERROR: [400, 401, 402, 403, 404, 500, 501],
};

export const checkHttpStatusCode = response => {
  if (HTTP_CODES?.SUCCESS?.includes(response?.status)) {
    return true;
  } else if (HTTP_CODES?.ERROR?.includes(response?.status)) {
    return false;
  }
};

export const checkHttpResponse = response => {
  if (checkHttpStatusCode(response) && checkIsValueNotNull(response)) {
    return true;
  } else {
    return false;
  }
};

export const checkIsValueNotNull = value => {
  if (value === null || undefined) {
    return false;
  } else {
    return true;
  }
};

export const createMultiplePackagesArray = obj => {
  let resultArray = [];
  for (let key in obj) {
    if (key === 'date-time') {
      continue;
    }
    let match = key?.match(/\d+/)[0]; // taking id from key name

    if (match) {
      let id = match[0];
      if (!resultArray[id]) {
        resultArray[id] = {};
      }
      resultArray[id][key] = obj[key];
    }
  }
  const maskedArray = resultArray
    ?.map((pkg, i) => {
      return {
        PackagingType: pkg[`package${i}`],
        Weight: pkg[`combinedWeight${i}`],
        Height: pkg[`packageHeight${i}`],
        Width: pkg[`packageWidth${i}`],
        Length: pkg[`packageLength${i}`],
        Qty: Number(pkg[`packageQuantity${i}`]),
        Unit: 'lb',
        Description: pkg[`Description${i}`] || '',
      };
    })
    ?.filter(pkg => {
      return pkg.PackagingType !== 'Select Package';
    })
    ?.filter(pkg => typeof pkg.PackagingType !== 'undefined');
  return maskedArray;
};

export const reMaskFormValues = arr => {
  let obj = {};
  for (let i = 0; i < arr?.length; i++) {
    const elm = arr[i];
    const curObj = {
      [`package${i}`]: elm.PackagingType,
      [`combinedWeight${i}`]: elm.Weight,
      [`packageHeight${i}`]: elm.Height,
      [`packageWidth${i}`]: elm.Width,
      [`packageLength${i}`]: elm.Length,
      [`packageQuantity${i}`]: elm.Qty,
      [`Description${i}`]: elm.Description || '',
    };
    obj = { ...obj, ...curObj };
  }
  return obj;
};

export const createIndexArr = arr => {
  return arr?.map((_, i) => i);
};

export const calculatePriceWithTax = (tax, price, name) => {
  const totalTax = tax?.Taxes?.reduce((total, tx) => tx.value + total, 0);
  const taxAmount = ((totalTax / 100) * price).toFixed(2);
  let finalPrice = price + Number(taxAmount);
  finalPrice = Number(finalPrice).toFixed(2);
  return { price: finalPrice, for: name };
};

export const maskPhoneNumber = phoneNumber => {
  phoneNumber = phoneNumber?.replace(/\D/g, '');
  const maskedNumber = `(${phoneNumber?.slice(0, 3)})${phoneNumber?.slice(
    3,
    6
  )}-${phoneNumber?.slice(6)}`;
  return maskedNumber;
};

export const findCurrentUserObject = userData => {
  const userId = getCurrentUserId();
  return userData?.Contacts?.filter(contact => contact?.ID === userId)[0];
};

export const downloadAttachmentUtils = imgData => {
  const blobData = convertBase64ToBlobData(imgData.Contents);
  const blob = new Blob([blobData], { type: 'image/png' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = imgData.FileName;
  link.click();
};

export const convertBase64ToBlobData = base64Data => {
  const contentType = 'image/png';
  const byteCharacters = atob(base64Data);
  const byteArrays = [];
  const sliceSize = 512;

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const trackingOrderStatusWithTime = orderStatusArray => {
  const obj = {};
  orderStatusArray?.forEach(elm => {
    if (elm.status === 'Submitted') {
      obj.submittedDate = elm.DateTime;
    }
    if (elm.status === 'Submitted') {
      obj.inProductionDate = elm.DateTime;
    }
    // if (elm.status == 'In Production') {
    //   obj.inProductionDate = elm.DateTime;
    // }
    if (elm.status === 'In-Transit') {
      obj.inTransitDate = elm.DateTime;
    }
    if (elm.status === 'Delivered') {
      obj.deliveryDate = elm.DateTime;
    }
  });
  return obj;
};

export const encodedBase64 = value => {
  try {
    return atob(value);
  } catch (error) {
    return '';
  }
};

export const formatPlaceholder = key => {
  let formattedPlaceholder = key.replace(/([a-z])([A-Z])/g, '$1 $2');

  formattedPlaceholder =
    formattedPlaceholder.charAt(0).toUpperCase() +
    formattedPlaceholder.slice(1);

  return formattedPlaceholder;
};

export const isObjectBlank = obj => {
  for (const key in obj) {
    if (obj[key] === undefined || obj[key] === '') {
      if (
        // all not required feilds
        key === 'CompanyName' ||
        key === 'ContactName' ||
        key === 'PhoneExt' ||
        key === 'PhoneExtForDelivery' ||
        key === 'AddressLine2' ||
        key === 'Comments' ||
        key === 'CommentsForDelivery' ||
        key === 'AddressLine2ForDelivery'
      ) {
        continue;
      }

      return true;
    }
  }
  return false;
};

export const isObjectBlankForDraft = obj => {
  if (
    (obj?.['package0'] === `Select Package` &&
      obj?.['packageQuantity0'] === 1) ||
    Object.keys(obj).length <= 0
  ) {
    return true;
  }
  for (const key in obj) {
    if (obj[key] === undefined || obj[key] === '') {
      return true;
    }
  }
  return false;
};

export const separatePickupAndDeliveryFormData = data => {
  let obj = {};
  for (const key in data) {
    if (key.includes('ForDelivery')) {
      obj.delivery = { ...obj.delivery, [`${key}`]: data[key] };
    } else {
      obj.pickup = { ...obj.pickup, [`${key}`]: data[key] };
    }
  }
  return obj;
};

export const maskGridForShowHideColumnMenuList = (userGrid, colObj) => {
  let arr = [];
  for (const key in colObj) {
    if (userGrid && JSON?.parse(userGrid).includes(key)) {
      arr.push({ colId: key, hide: false });
    } else {
      arr.push({ colId: key, hide: true });
    }
  }
  return arr;
};

export const maskGridForLocalStorage = (grid, maskedGrid, colObj) => {
  /// refector this logic
  return grid?.map((col, i) =>
    colObj[col.colId] === maskedGrid[i]?.field
      ? { ...col, hide: maskedGrid[i]?.hide }
      : col
  );
};

export const formatDateIn_12Hour = date => {
  moment(date).fromNow()
  const dateObject = moment(date, 'DD-MM-YYYY HH:mm:ss').toDate();
  return moment(date, 'DD-MM-YYYY HH:mm:ss').format('MM-DD-YYYY hh:mm:ss A');
};
export const toUTCFormat = (date, type) => {
  if (type === 'formatted') {
   return moment.utc(new Date(date), "MM-DD-YYYY hh:mm:ss A").format("YYYY-MM-DDTHH:mm:ss[Z]")
  }
  const utcDate = moment.utc(date, "DD-MM-YYYY HH:mm:ss");
  return utcDate.format("YYYY-MM-DDTHH:mm:ss[Z]")
}