import React from 'react';
import './invoice-view-order.scss';
import { commonConfig } from '../../../Config/config';
import { formatDateIn_12Hour } from '../../../Util/commonUtility';

const InvoiceViewOrder = ({ order }) => {

   const orderDataMask = {

    trackingNumber: order?.OnTime360Object?.TrackingNumber,
    submitedOn: formatDateIn_12Hour(order?.CreatedDate),

    fromCompanyName: order.CollectionLocation?.CompanyName,
    fromAddressLine1: order.CollectionLocation?.AddressLine1,
    fromCity: order.CollectionLocation?.City,
    fromPostalCode: order.CollectionLocation?.PostalCode,

    toCompanyName: order.DeliveryLocation?.CompanyName,
    toAddressLine1: order.DeliveryLocation?.AddressLine1,
    toCity: order.DeliveryLocation?.City,
    toPostalCode: order.DeliveryLocation?.PostalCode,

    description: order.OnTime360Object.Description,
    quantity: order.Quantity,
    weight: order?.OnTime360Object.Weight,
    searviceLevel: order.Quote.serviceName,

    subTotal:order.Quote.price
   }

  return (
    <div className='tracking-info-container'>
      <div className='tracking-header'>
        <div>
          <span>Tracking number: {orderDataMask.trackingNumber}</span>
        </div>
        <div>
          <span>Submitted on: {orderDataMask.submitedOn}</span>
        </div>
      </div>
      <div className='from-to-info'>
        <div className='from-wrapper'>
          <div className='from'>
            From:
            <span>
              {orderDataMask.fromCompanyName} <br />
              {orderDataMask.fromAddressLine1} <br />
              {orderDataMask.fromCity},{orderDataMask.fromPostalCode} <br />
              {commonConfig.country}
            </span>
          </div>
        </div>
        <div className='to-wrapper'>
          <div className='to'>
            To:
            <span>
              {orderDataMask.toCompanyName} <br />
              {orderDataMask.toAddressLine1} <br />
              {orderDataMask.toCity}, {orderDataMask.toPostalCode}<br />
              {commonConfig.country}
            </span>
          </div>
        </div>
      </div>
      <div className='tracking-desc-info'>
        <div className='for-desc'>
          <div className='desc-wrapper'>
            <div className='desc'>
              <p>Description :</p>
              <p>{orderDataMask.description || 'N/A'}</p>
            </div>
            <div className='desc'>
              <p>Requested by :</p>
              <p>{orderDataMask.fromCompanyName}</p>
            </div>
            <div className='desc'>
              <p>Quantity :</p>
              <p>{orderDataMask.quantity}</p>
            </div>
            <div className='desc'>
              <p>Weight :</p>
              <p>{orderDataMask.weight} {orderDataMask.weight ? commonConfig.weightUnit : '0'}</p>
            </div>
            <div className='desc'>
              <p>Delivered to :</p>
              <p>{orderDataMask.toCompanyName}</p>
            </div>
          </div>
        </div>
        <div className='sub-wrapper'>
          <div className='service-level'>
            Service level : <strong>{orderDataMask.searviceLevel}</strong>
          </div>
          <div className='sub-normal'>Subtotal: ${orderDataMask.subTotal}</div>
        </div>
      </div>
      <strong className='sub-strong'>Sub total: ${orderDataMask.subTotal}</strong>
    </div>
  );
};

export default InvoiceViewOrder;
