import React from 'react';
import './review.scss';
import { calculatePriceWithTax } from '../../../../Util/commonUtility';
import { commonConfig } from '../../../../Config/config';

const Review = props => {
  const { selectedService, tax, details, packagesArray } = props;
  const { CollectionLocation, DeliveryLocation } = details;

  const maskObjForLocation = location => {
    return {
      companyName: `${location?.CompanyName} ${location?.Comments}`,
      addressLine1: location?.AddressLine1,
      place: `${location?.City} Quebec ${location?.PostalCode}`,
      phone: location?.Phone,
      email: location?.Email,
    };
  };
  const collectionData = maskObjForLocation(CollectionLocation);
  const deliveryData = maskObjForLocation(DeliveryLocation);

  const getPriceBeforeTax = (tax) => {
      const orignalPrice = selectedService.service?.price;
      const taxAmount = ((tax / 100) * orignalPrice).toFixed(2);
      return taxAmount; 
  }

  const LocationDetails = ({ data, className }) => {
    return (
      <div className={className}>
        {data.companyName}
        <br />
        {data.place}
        <br />
        {data.addressLine1}
        <br />
        {data.phone}
        <br />
        {data.email}
      </div>
    );
  };

  return (
    <div className='review-order-container'>
      <div className='overview-txt-wrapper'>
        <div className='label-wrapper'>
          <span>Overview</span>
        </div>
        <main className='main-container'>
          <div className='details-wrapper'>
            <LocationDetails
              data={collectionData}
              className={'collection-details'}
            />
            <LocationDetails
              data={deliveryData}
              className={'delivery-details'}
            />
          </div>
          <div className="table-wrapper">

          <table className='review-table'>
            <thead>
              <tr>
                <th colSpan={3}>Packaging Type</th>
                <th>Length ({commonConfig.HWLUnit})</th>
                <th>Width ({commonConfig.HWLUnit})</th>
                <th>Height ({commonConfig.HWLUnit})</th>
                <th>Quantity</th>
                <th>Weight ({commonConfig.weightUnit})</th>
              </tr>
            </thead>
            <tbody>
              {packagesArray?.map((pkg, i) => (
                <React.Fragment key={i}>
                <tr>
                  <th colSpan={3} className='package-type'>{pkg?.PackagingType}</th>
                  <th>{pkg.Length}</th>
                  <th>{pkg.Width}</th>
                  <th>{pkg.Height}</th>
                  <th>{pkg.Qty}</th>
                  <th>{pkg.Weight}</th>
                </tr>
                </React.Fragment>
              ))}
              <tr className='table-bottom'>
                <th colSpan={7}></th>
                <th>{}</th>
              </tr>
              <tr className='table-bottom'>
                <th colSpan={7}>Subtotal</th>
                <th>$ {selectedService.service?.price}</th>
              </tr>
              {
                tax?.Taxes?.map(tx => (
              <tr className='table-bottom'>
                <th colSpan={7}>{tx?.name}:{tx?.taxNumber}</th>
                <th>$ {getPriceBeforeTax(tx.value)}</th>
              </tr>
                ))
              }
              <tr className='table-bottom'>
                <th colSpan={7}>FINAL TOTAL</th>
                <th>$ {calculatePriceWithTax(tax ,selectedService?.service.price, '')?.price}</th>
              </tr>
            </tbody>
          </table>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Review;
