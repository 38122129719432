import React, { useEffect, useRef } from 'react';
import './invoice-view.scss';
import { loginLogo } from '../../../Resource/images';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InvoiceViewOrder from '../../../Components/Invoice/view/invoiceViewOrder';
import { getInvoicesData } from '../../../Store/Action/invoices/invoiceAction';
import { commonConfig } from '../../../Config/config';
import generatePDF, { Margin, Resolution } from 'react-to-pdf';
import moment from 'moment';

const InvoicesView = () => {
  const { id: invoiceId } = useParams();
  const dispatch = useDispatch();
  const invoiceRef = useRef(null);
  const { invoiceData: invoiceData } = useSelector(state => state.InvoiceSlice);

  useEffect(() => {
    dispatch(getInvoicesData(invoiceId));
  }, [invoiceId]);

  const getPriceBeforEachTax = tax => {
    const originalPrice =
      invoiceData.InvoiceData?.TaxInformation.InvoiceAmountWithoutTax;
    return (tax / 100) * originalPrice;
  };

  const InvoiceDataMask = {
    billToName: invoiceData?.customerData?.Name,
    addressLine1: invoiceData?.customerData?.AddressLine1,
    city: invoiceData?.customerData?.City,
    state: invoiceData?.customerData?.State,
    invoiceDate: moment(new Date(invoiceData?.InvoiceData?.CreatedDate)).format('MM-DD-YYYY hh:mm:ss A'),
    dueDate: moment(new Date(invoiceData?.InvoiceData?.DueDate)).format('MM-DD-YYYY hh:mm:ss A'),
    invoiceNumber: invoiceData?.InvoiceData?.InvoiceNumber,
    total: invoiceData.InvoiceData?.TaxInformation.InvoiceAmountWithoutTax,
    netTotalAmount: invoiceData.InvoiceData?.TotalAmount,
    ordersData: invoiceData?.ordersData,
  };
  const printInvoiceHandler =  () => window.print();

  const downloadInvoiceHandler = async () => {
    
    const options = {
      filename: 'VNP-invoice.pdf',
      resolution: Resolution.NORMAL,
      page: {
        format: 'letter',
        margin: Margin.MEDIUM,
      },
    };
    return await generatePDF(invoiceRef, options);
  };

  return (
    <>
      <div className='invoice-view-container'>
        <div className='print-download-btns-container'>
        <button onClick={printInvoiceHandler}>Print</button>
        <button onClick={downloadInvoiceHandler}>Download</button>
      </div>
        <div className='pdf-view-wrapper' ref={invoiceRef}>
          <div className='invoice-pdf-view'>
            <div className='invoice-pdf-top'>
              <div className='invoice-top-logo'>
                <img src={loginLogo} alt={commonConfig.CompanyName} />
              </div>
              <div className='invoice-top-address'>
                <div className='address'>
                  {`${commonConfig.companyNameSmall} Transport`}
                  <br />
                  3590 Rue Griffith.
                  <br />
                  Saint-Laurent,
                  <br />
                  Quebec. H4T 1A7
                </div>
                <div className='invoice-top-bill-to'>
                  <div className='bill-text'>
                    Bill to:
                    <br />
                    <br />
                  </div>
                  {InvoiceDataMask.billToName}
                  <br />
                  {InvoiceDataMask.addressLine1}
                  <br />
                  {InvoiceDataMask.city},{InvoiceDataMask.state}
                </div>
              </div>
              <div className='invoice-top-details'>
                <div className='invoice-text'>INVOICE</div>
                <div className='invoice-details'>
                  Invoice date:
                  <span>{InvoiceDataMask.invoiceDate}</span>
                </div>
                <div className='invoice-details'>
                  Due Date:
                  <span>{InvoiceDataMask.dueDate}</span>
                </div>
                <div className='invoice-details'>
                  Invoice number: <span>{InvoiceDataMask.invoiceNumber}</span>
                </div>
                <div className='invoice-details transport-title'>
                  {`Contact ${commonConfig.companyNameSmall} Transport:`}
                </div>
                <div className='invoice-details'>
                  Phone: <span>{commonConfig.vnpContactNumber}</span>
                </div>
                <div className='invoice-details'>
                  Email: <span>{commonConfig.vnpEmail}</span>
                </div>
                <div className='invoice-details'>
                  Web Site: <span>{commonConfig.vnpWebsite}</span>
                </div>
                <div className='total-amount'>
                  <div className='amount-details'>
                    Total:
                    <span>
                      $
                      {
                        invoiceData.InvoiceData?.TaxInformation
                          .InvoiceAmountWithoutTax
                      }
                    </span>
                  </div>
                  {invoiceData.InvoiceData?.TaxInformation.TaxArray.map(
                    (data, i) => (
                      <div className='amount-details'>
                        {data.name} #{data.taxNumber}:{' '}
                        <span key={i}>
                          ${getPriceBeforEachTax(data.value).toFixed(2)}
                        </span>
                      </div>
                    )
                  )}
                  <div className='amount-details'>
                    Net Amount Due:{' '}
                    <span>${InvoiceDataMask.netTotalAmount}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='invoice-pdf-middle'>
              <div className='thank-text'>
                Thank you for your Business, we value your patronage and
                appreciate your <br />
                confidence in us. Counting you among our customers is something
                for which we <br />
                are especially grateful. <br />
                Please <br />
                {`Make all checks payable to ${commonConfig.CompanyName} Solutions 2015.`}{' '}
                <br />
              </div>
              <div className='manage-text'>
                Our management is always happy to serve you! if you have any
                comments and suggestions regarding our services please feel free
                to share it with us, our goal is to Improve to serve you better
              </div>
            </div>
            <div className='all-time-block'>
              <b>On Time All The Time </b>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            {InvoiceDataMask.ordersData?.map(order => (
              <InvoiceViewOrder key={order._id} order={order} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoicesView;
