import React, { useEffect, useRef, useState } from 'react';
import './invoices.scss';
import InvoicesGrid from '../../../Components/Common/AgGrid/invoicesGrid/invoicesGrid';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoicesList } from '../../../Store/Action/invoices/invoiceAction';
import { getUser } from '../../../Store/Action/user/userAction';
import moment from 'moment';
import { findCurrentUserObject } from '../../../Util/commonUtility';
import { useNavigate } from 'react-router-dom';

const Invoices = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [gridApi, setGridApi] = useState('');

  const userDetails = useSelector(state => state.AuthSlice);
  const inputRef = useRef();
  const { invoices: InvoicesList } =
    useSelector(state => state.InvoiceSlice) || [];

  const searchFilterHandler = e => {
    setSearchValue(e.target.value);
    gridApi?.setQuickFilter(e.target.value);
  };

  let invoiceNumber = 1;
  const { userObjId } = userDetails;
  const { userData } = useSelector(state => state.AuthSlice);
  const navigate = useNavigate();

  const currentUser = findCurrentUserObject(userData);

  useEffect(() => {
    dispatch(getUser());
  }, []);

  useEffect(() => {
    if (currentUser && !currentUser?.isInvoiceActiveContact) {
      navigate('/order/entry');
    }
  }, [currentUser])

  useEffect(() => {
    if (userObjId) {
      dispatch(getInvoicesList(userObjId));
    }
  }, [userObjId]);

  const colDef = [
    'invoice Number',
    'Payment Status',
    'Due Date',
    'Total Amount',
    'Total Tax',
    'Total Price',
    'GST%',
    'QST%',
  ];

  const maskedInvoicesData = InvoicesList?.map(invoice => {
    return {
      'invoice Number': (
        <span className='invoice-index-num' key={'invoice-number'}>
          {invoiceNumber++}
        </span>
      ),
      'Payment Status': (
        <span className={invoice?.IsPaid === 'Paid' ? 'is-paid' : 'not-paid'}>
          {invoice?.IsPaid}
        </span>
      ),
      'Due Date': moment(new Date(invoice?.DueDate)).format('DD/MM/YYYY'),
      'Total Amount': invoice?.TaxInformation?.InvoiceAmountWithoutTax,
      'Total Tax': invoice?.TaxInformation?.TotalTaxAmount,
      'Total Price': invoice?.TotalAmount,
      'GST%': invoice?.TaxInformation?.TaxArray[0]?.value,
      'QST%': invoice?.TaxInformation?.TaxArray[1]?.value,
      _id: invoice?._id,
    };
  });

  return (
    <div className='invoice-container'>
      <div className='invoice-header'>
        <input
          ref={inputRef}
          onChange={searchFilterHandler}
          type='search'
          placeholder='Search'
          className='invoice-search-box'
        />
      </div>
      <div className='invoice-grid-wrapper'>
        <InvoicesGrid
          inputRef={inputRef}
          data={maskedInvoicesData}
          setGridApi={setGridApi}
          colDef={colDef}
        />
      </div>
    </div>
  );
};

export default Invoices;
